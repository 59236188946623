export default {
  setUser(state, status) {
    state.user = status;
  },
  toggleSidebarDesktop(state) {
    const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow);
    state.sidebarShow = sidebarOpened ? false : 'responsive';
  },
  toggleSidebarMobile(state) {
    const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow);
    state.sidebarShow = sidebarClosed ? true : 'responsive';
  },
  set(state, [variable, value]) {
    state[variable] = value;
  },

  setAuthentication(state, status) {
    state.isAuthenticated = status;
  },

  logout(state, status) {
    state.isAuthenticated = status;
    state.user = {};
  },

  setDocumentTrackingSidebarBadge(state, status) {
    state.hasIncomingDocuments = status;
  },

  setSerialNumberRequestsSidebarBadge(state, status) {
    state.hasSerialNumberRequests = status;
  },

  setSuppressNotification(state, status) {
    state.suppressNotification = status;
  },
};
